import { Component, Prop, Vue } from 'vue-property-decorator'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { loadStripe } from '@stripe/stripe-js'

@Component({
  name: 'StripeCheckout'
})
export default class StripeCheckout extends Vue {
  @Prop() stripeKey!: any

  cardValue = '';

  get stripeCardRules () {
    return {
      required: true,
    }
  }

  data () {
    return {
      stripe: null
    }
  }

  async mounted () {
    const windowCur = window as any
    if (!windowCur.checkoutItem) {
      this.$data.stripe = await loadStripe(this.stripeKey.tokenData.stripe_key)
      windowCur.checkoutItem = await this.$data.stripe.initEmbeddedCheckout({ clientSecret: this.stripeKey.tokenData.stripe_client_secret})
    }
    try {
      windowCur.checkoutItem.unmount()
    } catch (error) {
      // do nothing unmount in case its already mounted
    }
    windowCur.checkoutItem.mount('#checkout')
    this.$emit('stripe-change', this.$data.stripe)
  }
}
